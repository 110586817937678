import React, { Component } from "react"
import { Link } from "gatsby"
import "../styles/products.scss"
import acuteLogo from '../assets/svgs/Acute1.svg'

class SingleProduct extends Component {
  render() {
    const { data = [] } = this.props
    return (

      <div className="individual-product-page">
        <div className=" banner-flash w-100">
          <div className="container py-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-5 text-center">
                <h6 className="prod-title">{data.prodHeading}</h6>
                <h2 className="title" style={{ letterSpacing: "-1.5px" }}>
                  {data.prodDesc}
                </h2>
                <div className="d-none d-md-block">
                  <br />
                  <Link to="#lets-talk">
                    <button type="button" className="btn request-button">
                      Request demo
                      </button>
                  </Link>
                </div>
              </div>

              <div className="col-lg-7 d-none d-lg-block">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide overflow-hidden"
                  data-ride="carousel"
                  data-interval="2000"
                >
                  <ol className="carousel-indicators">
                    {
                      data.prodImg.map((imagePath, index) => {
                        return <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={index}
                          className={!index ? 'active' : ''}
                        ></li>
                      })
                    }

                    {/* <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    ></li> */}
                  </ol>
                  <div className="carousel-inner">
                    {
                      data.prodImg.map((imagePath, index) => {
                        return <div className={`carousel-item ${index ? '' : 'active'}`}>
                          <img
                            className="d-block w-100"
                            src={imagePath}
                            alt={`Slide ${index}`}
                          />
                        </div>
                      })
                    }
                    {/* <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src={data.prodImg[0]}
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={data.prodImg[1]}
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={data.prodImg[2]}
                        alt="Third slide"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div className="container">
          <p className="product-desc read-more m-auto">{data.subDesc}</p>

          <br />

          <div className="product-features-wrapper m-auto">
            {data.para.map((item, index) => {
              return (
                <div className="row justify-content-center align-items-center py-4">
                  <div
                    className={`col-12 col-lg-5 ${index % 2 ? "" : "order-lg-2"
                      }`}
                  >
                    <img
                      src={item.img}
                      className="w-100 img-fluid responsive-img"
                    />
                  </div>
                  <div className="col-12 col-lg-7">
                    {item.content.map((item) => {
                      return (
                        <React.Fragment>
                          <h4 className="sub-title-lucrys">{item.head}</h4>
                          <p className="font-14">{item.desc}</p>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            {
              data.tableData?.length ?

                <div className="p-0 m-auto" style={{ maxWidth: "700px" }}>
                  <h4 className="sub-title p-0">Specifications</h4>
                  <table className="w-60 product-page-table">
                    <colgroup>
                      <col
                        style={{ backgroundColor: "rgba(175, 234, 246, 0.5)" }}
                      />
                    </colgroup>
                    <tbody>
                      {data.tableData.map(item => {
                        return (
                          <tr>
                            <td className="product-page-table-data">
                              {item.heading}
                            </td>
                            <td className="product-page-table-data">
                              {item.content}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <br />
                </div>
                : null
            }
          </div>

        </div>
        {/* <div className="acute">
          <h2 className="text-center sub-title">
            It s a crucial component in
                </h2>
          <div className="row text-center flex-row align-items-center foot-container">
            <div>
              <img src={acuteLogo} className="icon-style" />
              <h6 className="sub-title">Acute</h6>
            </div>
            <div className="acute-border"></div>
            <div>
              <Link to="#lets-talk">
                <button type="button" className="btn request-button">
                  Request demo
                      </button>
              </Link>
            </div>
          </div>
        </div> */}
      </div>

    )
  }
}
export default SingleProduct
