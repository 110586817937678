import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/products.scss"
import { Helmet } from "react-helmet"
import fftAnalysis from "../../assets/images/products/fft_analysis.png"
import failurePattern from "../../assets/images/products/failure_patterns.png"
import fourTellAiHome from "../../assets/images/products/4tellAI_home.png"
import fourTellAiDashboard from "../../assets/images/products/4tellAI_dashboard.png"
import iotGateway from "../../assets/images/products/iiot_gateway.png"
import flaskIODAsh from "../../assets/images/products/flask_io_dash.png"
import Trends from "../../assets/images/products/trends.png"
import paHome from "../../assets/images/products/pa_home.png"
import lucrysDashboard from "../../assets/images/products/lucrys_dashboard.png"
import Strands from "../../assets/images/products/strands.png"
import Utitlities from "../../assets/images/products/utitlities.png"



export const productsData = [
  {
    heading: 'FlashIO – IoT Kit',
    desc: 'A plug and play IoT kit for immediate deployments and connect to multiple data sources like PLCs, SCADA, DCS systems. Visualize the process data or machine data within few seconds. The IoT Kit includes PLC Drivers, OPC Clients, Remote Monitoring Application, Real Time Alerts & Notifications, Trends, User Management, KPIs Dashboarding.',
    btnLink: '/products/flashIO-iot-kit/',
    prodImage: [
      iotGateway,
      flaskIODAsh,
      Trends
    ]
  },
  {
    heading: '4TellAI – Condition Based Monitoring',
    desc: 'Untimely machine breakdowns are costly - both financially as well as in terms of reputation. You don’t want that. 4tellai allows you to avoid such unpleasant scenarios by automatically identifying critical issues with your machines.',
    btnLink: '/products/4tell-ai/',
    prodImage: [
      fourTellAiDashboard,
      fourTellAiHome,
      failurePattern,
      fftAnalysis
    ]
  },
  {
    heading: 'Lucrys Analytics',
    desc: 'We built Lucrys Analytics Platform so that you can use its AI & ML modules to detect anomalies in production process, identify defects in manufactured products, ensure production and efficiency. In short, it allows you to ensure your plant runs smoothly at all times',
    btnLink: '/products/lucrys-platform/',
    prodImage: [
      paHome,
      lucrysDashboard,
      Strands
    ]
  },
  {
    heading: 'eSave - Energy Analytics',
    desc: 'eSave component can connect to your energy meters through RS485, RS232, MODBUS protocols. Monitor vital energy parameters from anywhere across your handheld devices. Map your day-to-day energy consumption to your costs and revenues seamlessly. The plug and play feature of this suite will help you in deploy the solutions within no time.',
    btnLink: '/products/eSave/',
    prodImage: [Utitlities]
  },
]



class Products extends Component {

  render() {
    return (
      <Layout>
        <SEO title="Industrial IoT Products|Machstatz" />
        {/* <Helmet>
          <script type="application/ld+json">
            {`
                {
                  "@context": "https://machstatz.com",
                  "@type": "Organization",
                  "url": "https://machstatz.com/products",
                  "name": "Machstatz Bussiness Solutions",
                  "contactPoint": {
                    "@type": "Products",
                    "email": "info@machstatz.com",
                    "contactType": "Product Support"
                  }
                }
              `}
          </script>
        </Helmet> */}
        {productsData.map((item, index) => {
          return (
            <div className={index % 2 ? "product-even" : "product-odd"}>

              <div className="container product">
                <div className="row align-items-center justify-content-between m-0">

                  <div className={`col-lg-7 d-flex justify-content-center align-items-center p-0 overflow-hidden ${index % 2 ? "order-lg-2 pl-lg-4 " : "pr-lg-4 "}`}>
                    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="3000">
                      <ol class="carousel-indicators">
                        {
                          item.prodImage.map((imagePath, imageIndex) => {
                            return <li
                              data-target="#carouselExampleIndicators"
                              data-slide-to={imageIndex}
                              className={!imageIndex ? 'active': ''}
                            ></li>
                          })
                        }
                        {/* <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                      </ol>
                      <div class="carousel-inner">

                        {
                          item.prodImage.map((imagePath, imageIndex) => {
                            return <div className={`carousel-item ${imageIndex ? '' : 'active'}`}>
                              <img
                                className="d-block w-100"
                                src={imagePath}
                                alt={`Slide ${imageIndex}`}
                              />
                            </div>
                          })
                        }
                        {/* <div class="carousel-item active">
                          <img class="d-block w-100" style={{ heigh: '265px' }} src={item.prodImage[0]} alt="First slide" />
                        </div>
                        <div class="carousel-item">
                          <img class="d-block w-100" style={{ heigh: '265px' }} src={item.prodImage[1]} alt="Second slide" />
                        </div>
                        <div class="carousel-item" >
                          <img class="d-block w-100" style={{ heigh: '265px' }} src={item.prodImage[2]} alt="Third slide" />
                        </div> */}
                      </div>

                    </div>
                  </div>

                  <div className="col-lg-5 p-0 py-5">

                    <h1 className="sub-title-production">
                      {item.heading}
                    </h1>
                    <p className="prod-desc">
                      {item.desc}
                    </p>
                    <Link
                      to={item.btnLink}
                      className="btn learn-button d-none d-lg-block"
                    >
                      Learn more
                    </Link>

                    {/* <button type="button" className="btn learn-button ml-5 d-none d-lg-block">Learn more</button> */}
                  </div>

                </div>
              </div>
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default Products;

