import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SingleProduct from "../../shared-component/single-product-page"
import { productsData } from './index'
import neuralNetwork from "../../assets/images/neuralNetworks.png"

const data = {
    prodHeading:productsData[2].heading,
    prodDesc:'Increase the production and operational efficiency of plants with our AI-powered analytics.',
    prodImg: productsData[2]['prodImage'],
    subDesc:"Causation and correlation relationships give you an insight into what you should do more and what you should do less. Our advanced machine learning algorithm goes through every plant activity and gives you an accurate image of what's wokring and what's not.",
    para:[
      {
        content:[
          {
            head:'Artificial Intelligence & Machine Learning Algorithms for advance process & time optimization methods at process flow.',
            desc:'We apply artificial intelligence and machine learning tools to sensors, process parameters and production data to boost production efficiency. We use Prognostics models to predict crucial metrics suc has Remaininig Useful Life (RUL) and Root Cause Analytics (RCA).These metrics show the time an asset will optimally operate before its next failure. Simultaneously we iteratively trial and select the most appropriate algorithms to use.'
          }
        ],
        img:'/static/svgs/machine learning.svg'
      },
      {
        content:[
          {
            head:'Increased Production Efficiency',
            desc:"Lucrys Analytics uses a deep algorithm to ensure increased production efficiency and consistent production quality in two ways: Recurrent Neural Network (RNN) algorithm detects anomalies in real-time for process values, power consumption and other critical production parameters. Convolutional Neural Network (CNN) algorithm iidentifies surface defects on metaal plates and finished products."
          },
          {
            head:'Acurate Machine Failure Predictions',
            desc:"Machine failures can be extrememly costly, not only in terms of money but also reputation.Accurate prediction of machine failure allows you to attend to issues that will lead to failure, way before the actual crash.Lucrys Analytics uses advanced forecasting algorithm that features time series prediction, production forecasting and asset failure prediction."
          },
       ],
        img:neuralNetwork
      },
      {
        content:[
          {
            head:'Increase Overall Equipment Effectiveness (OEE)',
            desc:'Our algorithm ensures that you are consistently producing high-quality products, as fast as possible. We can assure this simply because our artificial intelligence and machine learning algorithm predicts machine failure and identifies anomalies that can potentially lead to an unplanned shutdown. Such precise predictions allow you to prevent such shutdowns and therefore ensure speed and availability of your assets at all times.'
          },
       ],
        img:'/static/svgs/OEE.svg'
      },
    ],
    tableData:[
      // {
      //   heading:'Vibration Sensors',
      //   content:'Triaxial Vibration Sensor'
      // },
      // {
      //   heading:'Communication',
      //   content:'Wi-Fi'
      // },
      // {
      //   heading:'Vibration Range',
      //   content:'+50g'
      // },
      // {
      //   heading:'Linearity',
      //   content:'1%'
      // },
      // {
      //   heading:'Frequency Range',
      //   content:'2~15KHz'
      // },
      // {
      //   heading:'Make & Model',
      //   content:'Third Party Vibration Sensor'
      // },
      // {
      //   heading:'Gateway',
      //   content:'Integrated Gateway'
      // },
    ]
  
  }



const LucrysAnalytics = () => (
    <Layout>
        <SEO title="Lucrys Platform" />
        <SingleProduct data={data}/>
    </Layout>
)

export default LucrysAnalytics